/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const WorkSans_100Thin = require('./WorkSans_100Thin.ttf');
export const WorkSans_200ExtraLight = require('./WorkSans_200ExtraLight.ttf');
export const WorkSans_300Light = require('./WorkSans_300Light.ttf');
export const WorkSans_400Regular = require('./WorkSans_400Regular.ttf');
export const WorkSans_500Medium = require('./WorkSans_500Medium.ttf');
export const WorkSans_600SemiBold = require('./WorkSans_600SemiBold.ttf');
export const WorkSans_700Bold = require('./WorkSans_700Bold.ttf');
export const WorkSans_800ExtraBold = require('./WorkSans_800ExtraBold.ttf');
export const WorkSans_900Black = require('./WorkSans_900Black.ttf');
export const WorkSans_100Thin_Italic = require('./WorkSans_100Thin_Italic.ttf');
export const WorkSans_200ExtraLight_Italic = require('./WorkSans_200ExtraLight_Italic.ttf');
export const WorkSans_300Light_Italic = require('./WorkSans_300Light_Italic.ttf');
export const WorkSans_400Regular_Italic = require('./WorkSans_400Regular_Italic.ttf');
export const WorkSans_500Medium_Italic = require('./WorkSans_500Medium_Italic.ttf');
export const WorkSans_600SemiBold_Italic = require('./WorkSans_600SemiBold_Italic.ttf');
export const WorkSans_700Bold_Italic = require('./WorkSans_700Bold_Italic.ttf');
export const WorkSans_800ExtraBold_Italic = require('./WorkSans_800ExtraBold_Italic.ttf');
export const WorkSans_900Black_Italic = require('./WorkSans_900Black_Italic.ttf');
