import { colorsPalette } from "./colorsPalette";
export default {
  // BACKGROUND
  $backgroundDefault: colorsPalette.white,
  $backgroundElevated: colorsPalette.white,
  $backgroundElevatedLight: colorsPalette.white,
  $backgroundNeutralHeavy: colorsPalette.grey20,
  $backgroundNeutralIdle: colorsPalette.grey40,
  $backgroundNeutralMedium: colorsPalette.grey60,
  $backgroundNeutral: colorsPalette.grey70,
  $backgroundNeutralLight: colorsPalette.grey80,
  $backgroundPrimaryHeavy: colorsPalette.violet30,
  $backgroundPrimaryMedium: colorsPalette.violet70,
  $backgroundPrimaryLight: colorsPalette.violet80,
  $backgroundGeneralHeavy: colorsPalette.blue30,
  $backgroundGeneralMedium: colorsPalette.blue70,
  $backgroundGeneralLight: colorsPalette.blue80,
  $backgroundSuccessHeavy: colorsPalette.green30,
  $backgroundSuccessLight: colorsPalette.green80,
  $backgroundWarningHeavy: colorsPalette.yellow30,
  $backgroundWarningLight: colorsPalette.yellow70,
  $backgroundMajorLight: colorsPalette.orange80,
  $backgroundMajorHeavy: colorsPalette.orange30,
  $backgroundDangerHeavy: colorsPalette.red30,
  $backgroundDangerLight: colorsPalette.red80,
  $backgroundDisabled: colorsPalette.grey50,
  $backgroundDark: colorsPalette.grey10,
  $backgroundDarkElevated: colorsPalette.grey10,
  $backgroundDarkActive: colorsPalette.grey20,
  $backgroundInverted: colorsPalette.grey10,
  // TEXT
  $textDisabled: colorsPalette.grey50,
  $textDefault: colorsPalette.grey10,
  $textNeutralHeavy: colorsPalette.grey20,
  $textNeutral: colorsPalette.grey30,
  $textNeutralLight: colorsPalette.grey40,
  $textDefaultLight: colorsPalette.white,
  $textPrimary: colorsPalette.violet30,
  $textGeneral: colorsPalette.blue30,
  $textSuccess: colorsPalette.green10,
  $textSuccessLight: colorsPalette.green30,
  $textMajor: colorsPalette.orange10,
  $textDanger: colorsPalette.red10,
  $textDangerLight: colorsPalette.red30,
  // ICON
  $iconDefault: colorsPalette.grey10,
  $iconNeutral: colorsPalette.grey20,
  $iconDefaultLight: colorsPalette.white,
  $iconPrimary: colorsPalette.violet30,
  $iconPrimaryLight: colorsPalette.violet50,
  $iconGeneral: colorsPalette.blue30,
  $iconGeneralLight: colorsPalette.blue50,
  $iconSuccess: colorsPalette.green10,
  $iconSuccessLight: colorsPalette.green30,
  $iconMajor: colorsPalette.orange10,
  $iconDanger: colorsPalette.red10,
  $iconDangerLight: colorsPalette.red30,
  $iconDisabled: colorsPalette.grey50,
  // OUTLINE
  $outlineDefault: colorsPalette.grey60,
  $outlineDisabled: colorsPalette.grey50,
  $outlineDisabledHeavy: colorsPalette.grey30,
  $outlinePrimary: colorsPalette.violet30,
  $outlineGeneral: colorsPalette.blue30,
  $outlineWarning: colorsPalette.yellow30,
  $outlineDanger: colorsPalette.red30,
  $outlineInverted: colorsPalette.white
};