import { useEffect, useState } from 'react'
import { Keyboard } from 'react-native'

export function useKeyboardHeight() {
  const [keyboardHeight, setKeyboardHeight] = useState(0)

  useEffect(() => {
    const showSubscription = Keyboard.addListener('keyboardDidShow', e =>
      setKeyboardHeight(e.endCoordinates.height)
    )
    const hideSubscription = Keyboard.addListener('keyboardDidHide', () => setKeyboardHeight(0))
    return () => {
      showSubscription.remove()
      hideSubscription.remove()
    }
  }, [setKeyboardHeight])

  return keyboardHeight
}
